export const ASSETS_URL = process.env.REACT_APP_ASSETS_URL

export const SHOW_EYE = {
  show: `${ASSETS_URL}/general/show-eye.svg`,
  hide: `${ASSETS_URL}/general/hide-eye.svg`
}

export const HOME_ICONS = {
  receivedIcon: `${ASSETS_URL}/home-icons/por-almacenar.svg`,
  packageIcon: `${ASSETS_URL}/home-icons/almacenados.svg`,
  truckIcon: `${ASSETS_URL}/home-icons/por-recibir.svg`,
  inventory: {
    '#36E08F': `${ASSETS_URL}/home-icons/inventory-green.svg`,
    '#FFBC57': `${ASSETS_URL}/home-icons/inventory-yellow.svg`,
    '#FF6157': `${ASSETS_URL}/home-icons/inventory-red.svg`
  }
}

export const NAV_ICONS = {
  deliver: `${ASSETS_URL}/home-icons/navbar-icons/Entregar.svg`,
  entry: `${ASSETS_URL}/home-icons/navbar-icons/Ingreso.svg`,
  packages: `${ASSETS_URL}/home-icons/navbar-icons/Pedidos.svg`,
  store: `${ASSETS_URL}/home-icons/navbar-icons/Almacenar.svg`,
  claim: `${ASSETS_URL}/home-icons/navbar-icons/Reclamar.svg`
}

export const NOTIFICATION_ICONS = {
  claimNotification: `${ASSETS_URL}/notification/claim-notification.png`,
  bolt: `${ASSETS_URL}/notification/bolt.svg`,
  timer: `${ASSETS_URL}/notification/timer.svg`
}

export const SCAN_FLOWS_ICONS = {
  packageBlue: `${ASSETS_URL}/scan-flows/package-blue.png`,
  packageGray: `${ASSETS_URL}/scan-flows/package-gray.png`,
  qrCodeBlue: `${ASSETS_URL}/scan-flows/qr-code-blue.png`,
  qrCodeGray: `${ASSETS_URL}/scan-flows/qr-code-gray.png`,
  claimPackage: `${ASSETS_URL}/scan-flows/claim-package.png`
}

export const FEEDBACK_ICONS = {
  errorIcon: `${ASSETS_URL}/feedback/error-icon.png`,
  successIcon: `${ASSETS_URL}/feedback/success-icon.png`,
  successEndIcon: `${ASSETS_URL}/feedback/success-end.png`,
  successBackground: `${ASSETS_URL}/feedback/success-background.png`
}

export const SEARCH_ICONS = {
  searchIcon: `${ASSETS_URL}/search/search-icon.svg`,
  filterPurple: `${ASSETS_URL}/search/filter-purple.png`,
  filterGray: `${ASSETS_URL}/search/filter-gray.png`,
  noPackages: `${ASSETS_URL}/search/no-packages.png`,
  noFilteredPackages: `${ASSETS_URL}/search/no-filtered-packages.png`,
  barcode: `${ASSETS_URL}/scan-flows/barcode.png`
}

export const whiteArrow = `${ASSETS_URL}/general/arrow-white.png`

export const userDefault = `${ASSETS_URL}/general/user.png`

export const checkWhite = `${ASSETS_URL}/general/check-white.png`

export const checkGreen = `${ASSETS_URL}/general/check-green.svg`

export const padLockIcon = `${ASSETS_URL}/general/pad-lock.png`

export const chevronDownIcon = `${ASSETS_URL}/general/chevron-down.png`

export const typeCodeIcon = `${ASSETS_URL}/general/type-code.png`

export const photoCamera = `${ASSETS_URL}/general/photo-camera.png`

export const cameraMode = `${ASSETS_URL}/general/camera-mode.svg`

export const galleryIcon = `${ASSETS_URL}/general/gallery-icon.svg`

export const returned = `${ASSETS_URL}/general/return2.svg`

export const arrowup = `${ASSETS_URL}/general/arrowup.svg`

export const arrowdown = `${ASSETS_URL}/general/arrowdown.svg`

export const dots = `${ASSETS_URL}/general/dots.svg`

export const deleted = `${ASSETS_URL}/general/delete.svg`

export const replenishment = `${ASSETS_URL}/general/replenishment2.svg`

export const manifest = `${ASSETS_URL}/general/manifest2.svg`

export const closedPackage = `${ASSETS_URL}/general/package.svg`

export const triangle = `${ASSETS_URL}/general/triangle.png`

export const greenTruck = `${ASSETS_URL}/general/green-truck.svg`

export const bluePackage = `${ASSETS_URL}/general/package-blue.svg`

export const reloadArrow = `${ASSETS_URL}/general/reload-arrow.svg`

export const store = `${ASSETS_URL}/home-icons/store.svg`

export const print = `${ASSETS_URL}/general/icon-print.svg`
