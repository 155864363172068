import React, { useState } from 'react'
import { chevronDownIcon } from '../../../constants/icons'
import Timer from './Timer'

const NotificationCard = ({ title, items, children }) => {
  const [hasBeenClicked, setHasBeenClicked] = useState(false)
  return (
    <div className="mt-4 flex w-full flex-col">
      <button
        type="button"
        className="relative flex h-40 w-full items-center justify-start gap-x-4 rounded-md bg-white px-2 md:px-8"
        onClick={() => setHasBeenClicked((prev) => !prev)}
      >
        <div className="flex flex-col">
          <div className="ml-2 text-base md:text-lg">{title}</div>
          <Timer
          item={items.length > 0 ? items.reduce(
            (oldest, item) => new Date(item.createdAt) < new Date(oldest.createdAt) ? item : oldest
          ) : {}}
            backgroundColor="bg-transparent"
          />
        </div>

        <div
          className={`absolute top-[50%] right-[5%] translate-x-[-50%] translate-y-[-50%]
            transition-transform ${hasBeenClicked ? '' : '-rotate-90'}`}
        >
          <img src={chevronDownIcon} className="h-auto w-4" alt="Ver más" />
        </div>
      </button>
      <div
        className={`transition-all duration-300 ${
          hasBeenClicked ? 'mt-2 h-full' : 'max-h-0 overflow-hidden'
        }`}
      >
        {children}
      </div>
    </div>
  )
}

export default NotificationCard
