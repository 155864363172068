import { React, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BottomNavbar from '../components/BottomNavbar'
import Loader from '../components/shared/Loader'
import SearchBar from '../components/shared/SearchBar'
import { getPointStats } from '../helpers/requests/pointInformation'
import { reloadArrow, userDefault } from '../constants/icons'
import ROUTES from '../constants/routes'
import useFetch from '../hooks/useFetch'
import Notifications from '../components/Home/Notifications/Notifications'
import PointStats from '../components/Home/PointStats'
import Chatbot from '../components/Chatbot'
import { MenuContext } from '../contexts/MenuContext'

const Home = () => {
  const [pointInfo, setPointInfo] = useState({})
  const navigate = useNavigate()

  const { isLoading, error } = useFetch(getPointStats, setPointInfo)
  const { isDistribution, setIsDistribution, isMobile, setIsMobile } = useContext(MenuContext)

  useEffect(() => {
    function handleWindowSizeChange() {
      setIsMobile(window.innerWidth <= 768)
    }

    handleWindowSizeChange()

    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [setIsMobile])

  return (
    <div className="h-fit min-h-screen w-full flex-col justify-center bg-light-gray text-center">
      <div className="h-fit pb-20 lg:pb-0">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="flex flex-col items-center justify-center">
            <div className="flex h-fit w-full items-center justify-center rounded-b-3xl bg-pinflag-dark py-4 text-white xs:flex-col xs:justify-center">
              <div className="relative flex w-2/3 items-center justify-between">
                {!error && (
                  <div className="flex w-1/3 items-center gap-4 xxs:justify-center">
                    <img className="w-16 " src={userDefault} alt="profile" />
                    <p className="font-bold">{pointInfo.warehouseInfo.nombrebodega}</p>
                  </div>
                )}
                <div
                  className="h-fit w-1/3 xxs:justify-center xs:mt-2 xs:w-full"
                  onClick={() => navigate(ROUTES.PACKAGES)}
                  role="button"
                  tabIndex={0}
                >
                  <SearchBar placeholder="Busca un pedido..." />
                </div>
                {!isMobile && (
                  <button
                    type="button"
                    className="mx-4 flex h-fit gap-2 rounded-md bg-blue p-2 text-center text-sm text-white"
                    onClick={() => setIsDistribution((prev) => !prev)}
                  >
                    {isDistribution ? 'PickUp' : 'Distribución'}
                    <img src={reloadArrow} alt="Cambiar Opciones" />
                  </button>
                )}
              </div>
            </div>
            <Chatbot />

            <div className="h-fit w-[90%] flex-col pb-28 lg:w-2/3">
              <Notifications />
              <PointStats pointInfo={pointInfo} error={error} />
            </div>
          </div>
        )}
        <BottomNavbar />
      </div>
    </div>
  )
}

export default Home
