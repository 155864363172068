import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Modal from '../shared/Modal/Modal'
import Button from '../shared/Button'
import ROUTES from '../../constants/routes'
import { ScanContext } from '../../contexts/ScanContext'
import { checkGreen } from '../../constants/icons'

const StoreModal = ({ packageData, readyToProcess }) => {
  const { setCodes, setSingleScan } = useContext(ScanContext)

  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  const closeModal = () => {
    setOpen(false)
  }

  const sendToStore = () => {
    setCodes([{ pinflagId: packageData.pinflagId, orderId: packageData.orderId }])
    setSingleScan(true)
    navigate(`${ROUTES.STORAGE}?storePickUp=true`)
  }

  const goHome = () => {
    navigate('/')
  }

  useEffect(() => {
    if (readyToProcess) {
      setOpen(true)
    }
  }, [readyToProcess, packageData])

  return (
    <div>
      <Modal show={open} handleClose={closeModal} customWidth="md:w-2/5 w-full">
        <div className="flex flex-col items-center justify-center">
        <img className="m-5 w-1/6" src={checkGreen} alt="box" />
        <div className="text-xl font-semibold">Pedido procesado con éxito</div>
        <div className="text-base font-light md:text-xl">Puedes almacenar el pedido de inmediato o más tarde.</div>
        <div className="mt-4 flex w-full flex-col">
          <Button
            color="bg-medium-green"
            textColor="text-white"
            shadow="drop-shadow-medium-green"
            onClick={sendToStore}
          >
            <div className="text-xl">Almacenar</div>
          </Button>
          <Button textColor="text-text-black" onClick={goHome}>
            <div className="text-xl">Más tarde</div>
          </Button>
        </div>
        </div>
      </Modal>
    </div>
  )
}

export default StoreModal
